import './Returns.css'

function Returns() {

    return (
        <>
            <section className='Returns sm:bg-contacts-bg'>
                <div className='container'>
                    <div className='containerWrapper'>
                        <div className='content bg-[#ffffff] bg-opacity-70 sm:bg-opacity-0 rounded-[20px] border-[1px] sm:border-none border-a-active-0  p-[20px] sm:p-[0px] '>
                            <h1 className='text-center'>Повернення та обмін</h1>
                            <p>
                                Ми прагнемо забезпечити високу якість продукції та послуг для наших клієнтів. Якщо ви не задоволені покупкою, ми пропонуємо можливість повернення або обміну товару відповідно до чинного законодавства України та умов, наведених нижче.
                            </p>
                            <div className='flex flex-col gap-[36px]'>
                                <h3 className=''>Умови повернення та обміну</h3>
                                <div className=' flex flex-col gap-[16px]'>
                                    <h6>Товари, що не підлягають поверненню:</h6>
                                    <ul>
                                        <li>Повітряні кульки, як товар, що входить до переліку непродовольчих товарів належної якості, які не підлягають поверненню (Постанова №172 від 19.03.1994).</li>
                                        <li>Персоналізовані вироби (з нанесеним текстом, малюнками тощо).</li>
                                        <li>Товари, які були у використанні або вийшли з ладу через неналежне використання.</li>
                                        <li>Декоративні конструкції, виготовлені на замовлення.</li>
                                    </ul>
                                </div>
                                <div className=' flex flex-col gap-[16px]'>
                                    <h6>Процес обміну:</h6>
                                    <ul>
                                        <li>Зв’яжіться з нами(<a className='underline' href='tel:+380631531173'>+380631531173</a>), щоб обговорити доступність товару для заміни.</li>
                                        <li>Після отримання та перевірки стану поверненого товару ми надішлемо обмінний товар.</li>
                                    </ul>
                                    
                                </div>
                                <div className=' flex flex-col gap-[16px]'>
                                    <h6>Доставка обміну:</h6>
                                    <ul>
                                        <li>У разі обміну з вини продавця (дефект товару) доставка буде безкоштовною.</li>
                                        <li>У разі обміну з особистих причин покупця доставка оплачується окремо.</li>
                                    </ul>
                                </div>
                                <div className=' flex flex-col gap-[16px]'>
                                    <h6>Зв'яжіться з нами:</h6>
                                    <ul>
                                        <li>Зателефонуйте за номером <a className='underline' href='tel:+380631531173'>+380631531173</a>, або любим зручним для вас способом щоб повідомити про бажання повернути або обміняти товар.</li>
                                    </ul>
                                </div>
                                <div className='flex justify-center'>
                                    <a href='/contacts' className='button buzok'>Зв'язатися</a>
                                </div>

                            </div>

                            

                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Returns