import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useBasket } from "../../context/BasketContext";
import BasicBreadcrumbs from "../../Components/Shops/Breadcrumbs/BasicBreadcrumbs";
import CardBallon from "../../Components/Shops/Shop/ShopCard/CardBallon/CardBallon";
import "./ProductDetail.css";
import Minus from "../../Assets/Icons/minus.svg";
import Plus from "../../Assets/Icons/plus.svg";
import Card from "../../Assets/Icons/card.svg";
import Geo from "../../Assets/Icons/geo.svg";
import ActiveColor from "../../Assets/Icons/activeColor.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// Import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Promo from "../../Components/Home/Promo/Promo";
import PopularCategory from "../../Components/All/PopularCategory/PopularCategory";

import { Alert, Snackbar } from "@mui/material";
import { transliterate } from "transliteration";
import defoltFoto from '../../Assets/Img/Shop/defoltFoto.png'; // Импортируем дефолтное фото

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function ProductDetail() {
  const { productTitle } = useParams();
  const [product, setProduct] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeColorIndex, setActiveColorIndex] = useState(0);
  const [quantities, setQuantities] = useState([]);
  const mainSwiperRef = useRef(null);
  const { addToBasket } = useBasket();
  const [open, setOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false); // Новое состояние для ошибки

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/products`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        
        const decodedTitle = decodeURIComponent(productTitle);
        const productData = data.products.find(
          (p) => transliterate(p.title) === decodedTitle
        );

        if (productData) {
          const colors = data.product_colors.filter(color => color.product_id === productData.id);
          setProduct({ ...productData, colors });
          setQuantities(colors.map(() => 0));
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [productTitle]);

  const handleColorClick = (index) => {
    setActiveColorIndex(index);
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      mainSwiperRef.current.swiper.slideTo(index);
    }
  };

  const handleSlideChange = (swiper) => {
    setActiveColorIndex(swiper.activeIndex);
  };

  const handleIncreaseQuantity = (index) => {
    setQuantities(quantities.map((qty, i) => (i === index ? qty + 1 : qty)));
    handleColorClick(index); // Установить активный цвет при увеличении количества
  };

  const handleDecreaseQuantity = (index) => {
    setQuantities(
      quantities.map((qty, i) => (i === index && qty > 0 ? qty - 1 : qty))
    );
    handleColorClick(index); // Установить активный цвет при уменьшении количества
  };

  const handleAddToBasket = () => {
    if (product.colors.length === 0) {
      setErrorOpen(true); // Показать уведомление об отсутствии цветов
    } else {
      let itemsAdded = false;
      product.colors.forEach((color, index) => {
        const quantity = quantities[index];
        if (quantity > 0) {
          addToBasket({ ...product, selectedColor: color, quantity });
          itemsAdded = true;
        }
      });
      if (itemsAdded) {
        setOpen(true);
        setQuantities(product.colors.map(() => 0)); // Сбросить количество до нуля после добавления в корзину
      } else {
        setErrorOpen(true); // Показать уведомление, если ни один товар не был добавлен
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorOpen(false); // Закрыть уведомление об ошибке
  };

  const getTotalQuantity = () => {
    return quantities.reduce((total, qty) => total + qty, 0);
  };

  const getTotalPrice = () => {
    return quantities.reduce((total, qty) => total + qty * product.price, 0);
  };

  if (!product) return <div>Loading...</div>;

  // Удаление дубликатов цветов
  const uniqueColors = Array.from(new Set(product.colors.map(color => color.color)))
    .map(color => {
      return product.colors.find(c => c.color === color);
    });

  // Проверка наличия фотографий
  const hasPhotos = product.colors.some(color => color.photo);

  return (
    <>
      <section className="ProductDetail">
        <div className="container">
          <BasicBreadcrumbs product={product} />
          <div className="grid grid-cols-12 gap-[20px] mt-[20px]">
            <div className="col-span-12 sm:col-span-5">
              <Swiper
                ref={mainSwiperRef}
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="ProductDetailSwiper2 mySwiper2"
                initialSlide={activeColorIndex}
                onSlideChange={handleSlideChange}
              >
                {hasPhotos ? (
                  product.colors.map((color, index) => (
                    <SwiperSlide key={index}>
                      <img src={color.photo ? `${apiUrl}/uploads/${color.photo}` : defoltFoto} alt={`color-${index}`} />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img src={defoltFoto} alt="default" />
                  </SwiperSlide>
                )}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="ProductDetailSwiper mySwiper"
              >
                {hasPhotos ? (
                  product.colors.map((color, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={() => handleColorClick(index)}
                    >
                      <img src={color.photo ? `${apiUrl}/uploads/${color.photo}` : defoltFoto} alt={`thumb-${index}`} />
                    </SwiperSlide>
                  ))
                ) : (
                  <SwiperSlide>
                    <img src={defoltFoto} alt="default" />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
            <div className="ProductDetail__content">
              <h2>{product.title}</h2>
              <div className="content__inner">
                <p className="content__description">{product.description}</p>
                <div className="ProductDetail__price">
                  {/* <p className="old__price">{product.price_old} грн/шт.</p> */}
                  {product.price_old && <p className="old__price">{product.price_old} грн/шт.</p>}
                  <p className="actual__price">{product.price} грн/шт.</p>
                </div>
                <div className="ColorPanels">
                  {uniqueColors.map((color, index) => (
                    <div key={index} className="ColorPanel">
                      <div
                        className="relative flex items-center"
                        onClick={() => handleColorClick(index)}
                      >
                        <CardBallon color={color.color} />
                        {index === activeColorIndex && (
                          <img
                            className="ActiveColor active"
                            src={ActiveColor}
                            alt="active"
                          />
                        )}
                      </div>
                      <div className="ColorSwith">
                        <div
                          className="ColorBtn dark"
                          onClick={() => handleDecreaseQuantity(index)}
                        >
                          <img src={Minus} alt="" />
                        </div>
                        <span className="w-[28px] text-center">
                          {quantities[index]}
                        </span>
                        <div
                          className="ColorBtn"
                          onClick={() => handleIncreaseQuantity(index)}
                        >
                          <img src={Plus} alt="" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="PriceTable">
                  <div>
                    <p>Кількість:</p>
                    <p>
                      <span>{getTotalQuantity()}</span> шт
                    </p>
                  </div>
                  <div>
                    <p>Повна ціна:</p>
                    <p>
                      <span>{getTotalPrice()}</span> грн
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="button" onClick={handleAddToBasket}>
                  В корзину
                </div>
                <div className="info">
                  <div className="flex flex-col gap-[8px]">
                    <h6>Способи доставки:</h6>
                    <p>1) Кур'єрська доставка</p>
                    <p>2) Самовивіз</p>
                    <div className="infoItem">
                      <img src={Card} alt="" />
                      <Link to="/delivery">
                        <p>Умови доставки та оплати</p>
                      </Link>
                    </div>
                    <div className="infoItem">
                      <img src={Card} alt="" />
                      <Link to="/returns">
                        <p>Повернення та обмін</p>
                      </Link>
                    </div>
                    <div className="infoItem infoItemDeskHidden ">
                      <img src={Geo} alt="" />
                      <Link to="/contacts">
                        <p>Контакти</p>
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[8px]">
                    <h6>Способи оплати:</h6>
                    <p>1) Готівкою при отриманні</p>
                    <p>2) Оплата за реквізитами</p>
                    <div className="infoItem infoItemMobHidden ">
                      <img src={Geo} alt="" />
                      <Link to="/contacts">
                        <p>Контакти</p>
                      </Link>
                    </div>
                  </div>
                  
                </div>
                {/* <div className="info">
                  <div className="infoItem">
                    <img src={Card} alt="" />
                    <Link to="/delivery">
                      <p>Умови доставки та оплати</p>
                    </Link>
                  </div>
                  <div className="infoItem">
                    <img src={Geo} alt="" />
                    <Link to="/contacts">
                      <p>Контакти</p>
                    </Link>
                  </div>
                </div> */}
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Товар додано до кошика
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Будь ласка, виберіть хоча б один товар
        </Alert>
      </Snackbar>
      <Promo />
      <PopularCategory />
    </>
  );
}

export default ProductDetail;